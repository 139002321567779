import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CaseService } from '@shared/services/case.service';
import { GlobalVariablesService } from '@shared/services/global-variables.service';

@Component({
  selector: 'cm-cases',
  templateUrl: './list-cases.component.html',
  styleUrls: ['./list-cases.component.scss'],
})
export class ListCasesComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public totalRecords = 0;
  pagination = { startAt: 0, maxResults: 20 };
  displayedColumns: string[] = [
    'id',
    'referenceId',
    'type',
    'category',
    'additionalInfo',
    'reportedAt',
    'sla',
    'status',
    'hasPendingRequest',
    'hasNotReviewedReply',
  ];
  public casesListing = new MatTableDataSource(new Array<any>());

  viewDetails(id: string) {
    this.router.navigate(['/case', id]);
  }

  sortCases(column: string) {
    this.currentSecondarySortColumn = null;
    if (this.currentSortColumn === column) {
      this.currentSortDirection =
        this.currentSortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.currentSortDirection = 'ASC';
    }
    this.currentSortColumn = column;
    this.filterCases();
  }

  currentSortColumn: string | null = 'Status';
  currentSecondarySortColumn: string | null = 'Created';
  currentSortDirection: 'ASC' | 'DESC' = 'ASC';
  currentSecondarySortDirection: 'ASC' | 'DESC' = 'DESC';

  public form: FormGroup = new FormGroup({
    caseId: new FormControl(''),
    referenceId: new FormControl(''),
    status: new FormControl(''),
    reportedAt: new FormControl(),
  });

  formatDate(date: Date) {
    return new Date(date).toLocaleDateString();
  }

  filterCases() {
    const filterValues = this.form.value;
    this.loadCases({
      ...filterValues,
      sortColumn: this.currentSortColumn,
      sortDirection: this.currentSortDirection,
      secondarySortColumn: this.currentSecondarySortColumn,
      secondarySortDirection: this.currentSecondarySortDirection,
    });
  }

  loadCases(filters: any = {}) {
    this.caseService
      .getCases({
        page: this.pagination.startAt,
        pageSize: this.pagination.maxResults,
        ...filters,
      })
      .subscribe({
        next: response => {
          this.casesListing = new MatTableDataSource(response.cases);
          this.totalRecords = response.total;
        },
      });
  }

  pageChangeEvent(event: { pageIndex: number; pageSize: number }) {
    this.caseService
      .getCases({
        page: event.pageIndex,
        pageSize: event.pageSize,
        ...this.form.value,
      })
      .subscribe({
        next: response => {
          this.casesListing = new MatTableDataSource(response.cases);
          this.totalRecords = response.total;
        },
      });
  }

  constructor(
    private router: Router,
    private caseService: CaseService,
  ) {
    this.caseService
      .getCases({
        page: this.pagination.startAt,
        pageSize: this.pagination.maxResults,
        caseId: null,
        reportedAt: null,
        sortColumn: null,
      })
      .subscribe({
        next: response => {
          this.casesListing = new MatTableDataSource(response.cases);
          this.totalRecords = response.total;
        },
      });
  }
}
