<form [formGroup]="categoryFilter" class="cases-type-filter">
  <mat-form-field class="instance-filter" appearance="outline">
    <mat-label>Case Type Instance</mat-label>
    <mat-select formControlName="caseTypeInstanceId">
      <mat-option *ngFor="let instance of caseTypeInstances" [value]="instance.id">
        {{instance.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" />
  </mat-form-field>
  <button mat-stroked-button class="apply-filter-button">Filter</button>
</form>

<div class="table-framed">
  <table mat-table [dataSource]="dataSource" [matSortActive]="sortColumn" [matSortDirection]="sortDirection" matSort matSortDisableClear>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>

    <mat-text-column name="code" headerText="Code"></mat-text-column>

    <ng-container matColumnDef="deployed">
      <th mat-header-cell *matHeaderCellDef class="bold">
        Deployed
      </th>
      <td mat-cell *matCellDef="let category">
        <storybook-status-label
          [color]="category.deployed ? 'green' : 'red'"
          [showDot]="true"
          [text]="category.deployed ? 'Deployed' : 'Not deployed'">
        </storybook-status-label>
      </td>
    </ng-container>

    <ng-container matColumnDef="enabled">
      <th mat-header-cell *matHeaderCellDef class="bold">
        Enabled
      </th>
      <td mat-cell *matCellDef="let category">
        <storybook-status-label
          [color]="category.enabled ? 'green' : 'red'"
          [showDot]="true"
          [text]="category.enabled ? 'Enabled' : 'Disabled'">
        </storybook-status-label>
      </td>
    </ng-container>

    <ng-container class="table__row" matColumnDef="validFrom">
      <th mat-header-cell *matHeaderCellDef class="bold">
        Valid From
      </th>
      <td mat-cell *matCellDef="let category">{{ category.validFrom | dateFormatPipe }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table__head"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)" class="table__row"></tr>
  </table>

  <mat-paginator
    #paginator
    [length]="totalRecords"
    [pageSize]="20"
    [pageSizeOptions]="[20, 50]"
    [showFirstLastButtons]="true">
  </mat-paginator>
</div>
