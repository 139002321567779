import { Component, Input } from '@angular/core';
import { CaseModel, caseState } from '../../case.model';
import { CaseService } from '@shared/services/case.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cm-action-details',
  templateUrl: './action-details.component.html',
  styleUrls: ['./action-details.component.scss'],
})
export class ActionDetailsComponent {
  @Input() data!: CaseModel;

  updateState(value: caseState) {
    this.caseService
      .update(this.route.snapshot.params['id'], {
        status: value,
      })
      .subscribe(res => {
        // this.router.navigate(['/case', this.route.snapshot.params['id']]);
      });
  }

  constructor(
    private caseService: CaseService,
    private route: ActivatedRoute
  ) {}
}
