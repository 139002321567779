<div class="main-header">
  <mat-icon
    (click)="drawer.toggle()"
    aria-hidden="false"
    aria-label="menu icon"
    fontIcon="menu"></mat-icon>
  <img class="logo-img" src="/assets/icons/vr_logo.svg"  alt="logo img"/>
  <span>Case Management</span>
  <mat-form-field appearance="outline" class="district-select">
    <mat-label>District</mat-label>
    <mat-select (selectionChange)="handleSelection($event)" [(value)]="selected" disabled>
      @for (district of districts; track district) {
        <mat-option [value]="district.value">{{ district.viewValue }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="middle-fill">
  </div>
  <cm-user-menu></cm-user-menu>
</div>
<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
    <div class="side-nav">
      <a [routerLink]="['/cases']" class="admin-menu--item">Customer feedback</a>
      <a href="https://thr2.fawful.se/" class="admin-menu--item">THR</a>
      <a href="https://ecase.fawful.se/" class="admin-menu--item">Ecase cases</a>
      <br />
      <a [routerLink]="['/categories']" class="admin-menu--item">Categories</a>
      <!-- <div class="app__build-version-container">
        <span>CM Version | {{ spaVersionId }}</span>
      </div> -->
    </div>
  </mat-drawer>

  <div class="example-sidenav-content">
    <app-loader-overlay></app-loader-overlay>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
