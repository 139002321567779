import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export type pillColor = 'red' | 'green' | 'yellow' | 'orange' | 'gray' | 'blue';

@Component({
  selector: 'cm-status-dropdown',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './status-dropdown.component.html',
  styleUrl: './status-dropdown.component.scss',
})
export class StatusDropdownComponent {
  @Input() color!: pillColor;
  @Input() text!: string;
}
