import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { L10nService } from '@vr/vr-common-lib';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { GlobalVariablesService } from '@shared/services/global-variables.service';
import { MatSelectChange } from '@angular/material/select';

interface District {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'cm-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  spaVersionId = environment.spaVersionId;
  showFiller = false;
  activeRoute: any;
  title = 'Case Management';

  districts: District[] = [{ value: '940', viewValue: 'Tåg i Bergslagen' }];
  selected = '940';

  async ngOnInit() {
    this.setPageTitle();
  }

  setPageTitle(): void {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map(() => {
          const child: ActivatedRoute | null = this.activeRoute.firstChild;
          const pageTitle = child && child.snapshot.data['pageTitle'];
          if (pageTitle) {
            const tmpTitle = pageTitle.split('.');
            return this.translateService.translate(tmpTitle[0], tmpTitle[1]);
          }
          return '';
        }),
      )
      .subscribe(title => {
        if (title) {
          title.then((res: any) => (this.title = res));
        }
      });
  }

  handleSelection(event: MatSelectChange) {
    this.globals.setDistrict(event.value);
  }

  constructor(
    private router: Router,
    private translateService: L10nService,
    private globals: GlobalVariablesService
  ) {
    this.globals.setDistrict(this.selected);
  }
}
