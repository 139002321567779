import { Component, Inject } from '@angular/core';
import { CaseTypeInstance, CategoryInstance } from '../category.model';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { ContentWrapperComponent } from '@shared/components/content-wrapper/content-wrapper.component';
import { TitleComponent } from '@shared/components/title/title.component';
import { CategoryService } from '@shared/services/category.service';
import { editCategoryFormGroup } from '@shared/utils/form-utils';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatOption, MatSelect } from '@angular/material/select';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'cm-category-details',
  standalone: true,
  imports: [
    MatDatepicker,
    MatLabel,
    MatFormField,
    FormsModule,
    MatInput,
    MatButton,
    ReactiveFormsModule,
    ContentWrapperComponent,
    TitleComponent,
    MatDialogContent,
    MatDialogActions,
    MatCheckbox,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatSelect,
    MatOption,
    NgForOf,
  ],
  templateUrl: './category-details.component.html',
  styleUrl: './category-details.component.scss',
})
export class CategoryDetailsComponent {
  editCaseCategoryForm: FormGroup = editCategoryFormGroup();
  caseTypeInstances: CaseTypeInstance[] = [];
  caseTypeInstanceCategories: CategoryInstance[] = [];

  constructor(
    private categoryService: CategoryService,
    public dialogRef: MatDialogRef<CategoryDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {category: CategoryInstance, caseTypeInstances: CaseTypeInstance[], caseTypeInstanceCategories: CategoryInstance[]}
  ) {
    this.caseTypeInstances = data.caseTypeInstances;
    this.caseTypeInstanceCategories = data.caseTypeInstanceCategories.filter(item => item.id !== data.category.id);

    this.categoryService.getCategoryDetails(data.category.id).subscribe({
      next: (categoryDetails) => {
        this.editCaseCategoryForm = editCategoryFormGroup(categoryDetails);
      },
      error: (error) => {
        console.error('Error getting category details:', error);
      },
    });
  }

  updateItem() {
    this.categoryService.updateCategoryDetails(this.editCaseCategoryForm.getRawValue()).subscribe({
      next: () => {
        this.dialogRef.close(this.editCaseCategoryForm.value);
      }
    });
  }

  deleteItem() {
    const categoryId = this.editCaseCategoryForm.getRawValue().id;
    this.categoryService.deleteCategory(categoryId).subscribe({
      next: () => {
        this.dialogRef.close(categoryId);
      }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
