import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { CaseService } from '@shared/services/case.service';
import { CaseModel } from '../../case.model';
import { BehaviorSubject } from 'rxjs';
import { FocusTextareaDirective } from '@shared/directives/focus-textarea.directive';
import { AutoScrollDirective } from '@shared/directives/auto-scroll.directive';
import { ActivatedRoute } from '@angular/router';
import { DescriptionType } from '@shared/enums';

@Component({
  selector: 'cm-correspondence',
  templateUrl: './correspondence.component.html',
  styleUrl: './correspondence.component.scss',
})
export class CorrespondenceComponent {
  @Input() data!: CaseModel;

  _isSelected = false;

  set isSelected(value: boolean) {
    this._isSelected = value;
  }

  form: FormGroup;

  @ViewChild(FocusTextareaDirective) textareaDirective!: FocusTextareaDirective;
  @ViewChild(AutoScrollDirective) autoscrollDirective!: AutoScrollDirective;

  showButtons: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showButtons$ = this.showButtons.asObservable();

  createdCommentId = new BehaviorSubject<{ id: string }>({ id: '' });
  createdCommentId$ = this.createdCommentId.asObservable();

  constructor(private fb: NonNullableFormBuilder, public caseService: CaseService, private route: ActivatedRoute) {
    this.form = this.createForm();
  }

  submitCorrespondence(correspondenceId: number | null) {
    if (this.form.invalid) {
      return;
    }

    const { comment, sendToPartner } = this.form.value;

    if (correspondenceId) {
      this.addMessageToCorrespondence({ comment, correspondenceId, sendToPartner });
      this.cancel();
      return;
    }

    this.createCorrespondence({ comment, sendToPartner });
    this.cancel();
  }

  markAsCompleted(correspondenceId: number) {
    this.caseService
      .markAsCompleted(correspondenceId)
      .subscribe(value => {
      });
  }

  createCorrespondence({
                comment,
                sendToPartner,
              }: {
    comment: string;
    sendToPartner: string;
  }) {
    this.caseService
      .addComment(this.route.snapshot.params['id'], { comment, sendToPartner })
      .subscribe(value => {
        this.createdCommentId.next(value as { id: string });
        this.caseService
          .getCase({ id: this.route.snapshot.params['id'] })
          .subscribe(data => {
            this.data = data.case;
          });
      });
  }

  addMessageToCorrespondence({
                comment,
                correspondenceId,
                sendToPartner,
              }: {
    comment: string;
    correspondenceId: number;
    sendToPartner: string;
  }) {
    this.caseService
      .addMessageToCorrespondence(this.route.snapshot.params['id'], correspondenceId, { comment, sendToPartner })
      .subscribe(value => {
        this.caseService
          .getCase({ id: this.route.snapshot.params['id'] })
          .subscribe(data => {
            this.data = data.case;
          });
      });
    }

  onTextInput() {
    const { comment } = this.form.value;
    if (comment.length > 0) {
      return;
    }
  }

  handlerHandler() {
    this.showButtons.next(true);
    this.autoscrollDirective.scrollToElement('.cm-correspondence');
  }

  cancel() {
    this.form.reset();
    this.isSelected = false;
    this.showButtons.next(false);
    this.textareaDirective.changeTextareaDimension();
  }

  private createForm(): FormGroup {
    return this.fb.group({
      comment: [''],
      sendToPartner: ['internal'],
    });
  }
}
