import { Component, ViewChild } from '@angular/core';
import { ListCategoriesComponent } from '../list-categories/list-categories.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { CreateCategoryComponent } from '../create-category/create-category.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cm-master-categories',
  standalone: true,
  imports: [
    ListCategoriesComponent,
    MatButton,
    MatIcon,
    PageHeaderComponent,
  ],
  templateUrl: './master-categories.component.html',
  styleUrl: './master-categories.component.scss',
})
export class MasterCategoriesComponent {
  @ViewChild(ListCategoriesComponent) listCategoriesComponent!: ListCategoriesComponent;

  constructor(
    public dialog: MatDialog,
  ) {
  }

  openCreateDialog(): void {
    const caseTypeInstances = this.listCategoriesComponent.caseTypeInstances;
    const selectedCaseTypeInstance = this.listCategoriesComponent.selectedCaseTypeInstance;
    const caseTypeInstanceCategories = this.listCategoriesComponent.dataSource.data;

    const dialogRef = this.dialog.open(CreateCategoryComponent, {
      data: { caseTypeInstances, caseTypeInstanceCategories, selectedCaseTypeInstance }
    });
  }
}
