<div class="cm-case-details__container">
  <div class="cm-case-details__container__details" data-test-id="case-source">
    <span>Source</span>
    <span>{{ data.source ?? '' }}</span>
  </div>

  <div
    class="cm-case-details__container__details"
    data-test-id="case-reportedAt">
    <div class="info-container">
      <span>Reported At</span>
      <mat-icon
        class="label-icon"
        fontIcon="info_outlined"
        matTooltip="This data means the case was reported by a service desk operator. As most cases will be reported by external partners this will most likely correspond to when the case was created externally."
        matTooltipClass="cm-tooltip"></mat-icon>
    </div>
    <span>{{ (data.reportedAt | date: 'MMM dd, hh:mm a') ?? '-' }}</span>
  </div>

  <div
    class="cm-case-details__container__details"
    data-test-id="case-eventDate">
    <div class="info-container">
      <span>Created At</span>
      <mat-icon
        class="label-icon"
        fontIcon="info_outlined"
        matTooltip="This data means the case was created in the Case Management System"
        matTooltipClass="cm-tooltip"
        matTooltipPosition="right"></mat-icon>
    </div>
    <span>{{ (data.createdAt | date: 'MMM dd, hh:mm a') ?? '-' }}</span>
  </div>

  <div
    class="cm-case-details__container__details"
    data-test-id="case-eventDate">
    <div class="info-container">
      <span>Occurred On</span>
      <mat-icon
        class="label-icon"
        fontIcon="info_outlined"
        matTooltip="This data contain time of the incident. When the reported case occurred in real time."
        matTooltipClass="cm-tooltip"
        matTooltipPosition="right"></mat-icon>
    </div>
    <span>{{ (data.eventDate | date: 'MMM dd, hh:mm a') ?? '-' }}</span>
  </div>
  <div
    class="cm-case-details__container__details"
    data-test-id="case-fromStation">
    <span>From</span>
    <span>{{ data.fromStation ?? '-' }}</span>
  </div>
  <div
    class="cm-case-details__container__details"
    data-test-id="case-toStation">
    <span>To</span>
    <span>{{ data.toStation ?? '-' }}</span>
  </div>
  <div class="cm-case-details__container__details" data-test-id="case-line">
    <span>Line</span>
    <span>{{ data.line ?? '-' }}</span>
  </div>
  <div
    class="cm-case-details__container__details"
    data-test-id="case-vehicle-number">
    <span>Vehicle Number</span>
    <span>{{ data.vehicleNumber ?? '-' }}</span>
  </div>
  <div
    class="cm-case-details__container__details"
    data-test-id="case-category1">
    <span>Category 1</span>
    <span>{{ data.category1 ?? '-' }}</span>
  </div>
  <div
    class="cm-case-details__container__details"
    data-test-id="case-category2">
    <span>Category 2</span>
    <span>{{ data.category2 ?? '-' }}</span>
  </div>
  <div
    class="cm-case-details__container__details"
    data-test-id="case-category2">
    <span>Staff Member Id</span>
    <span>{{ data.staffMemberId ?? '-' }}</span>
  </div>
  <div
    class="cm-case-details__container__details"
    data-test-id="case-category2">
    <span>Staff Member Role</span>
    <span>{{ data.staffMemberRole ?? '-' }}</span>
  </div>
</div>
