import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {
  private district: string = '';

  constructor() {}

  setDistrict(district: string) {
    this.district = district;
  }

  getDistrict(): string {
    return this.district;
  }
}
