import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariablesService } from '@shared/services/global-variables.service';

@Injectable()
export class DistrictInterceptor implements HttpInterceptor {
  constructor(public globals: GlobalVariablesService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const modifiedReq = req.clone({
      setHeaders: {
        "district-id": this.globals.getDistrict()
      },
    });

    return next.handle(modifiedReq);
  }
}
