<div *ngIf="data.correspondence && data.correspondence.length > 0;">
  <div *ngFor="let correspondence of data.correspondence; let i = index" class="cm-correspondence" cmAutoScroll>
    <h3>Correspondence ID:{{correspondence.id}}</h3>
    <div class="cm-correspondence__interaction-wrapper" [formGroup]="form">
      <div class="cm-correspondence__inputs">
      <textarea
        cmFocusTextarea
        class="cm-correspondence__inputs__textarea"
        (focus)="handlerHandler()"
        (input)="onTextInput()"
        rows="1"
        formControlName="comment"
        maxlength="4000"
        placeholder="Add correspondence..."></textarea>
      </div>

      <div class="cm-correspondence__buttons" *ngIf="showButtons$ | async">
        <button mat-flat-button (click)="submitCorrespondence(correspondence.id)">Submit</button>
        <button mat-flat-button (click)="markAsCompleted(correspondence.id)">Mark as completed</button>
        <button mat-button (click)="cancel()">Cancel</button>
      </div>
    </div>
    <br />

    <div class="cm-correspondence__correspondence">
      <div *ngFor="let message of correspondence.correspondenceMessages">
        <div class="cm-correspondence__correspondence__header">
          <div class="cm-correspondence__correspondence__header__content">
            <span *ngIf="message.senderEmail">{{ message.senderEmail }}</span>
            <span>{{ message.createdUtc | dateFormatPipe }}</span>
          </div>
        </div>
        <span>{{ message.messageContent }}</span>
      </div>
    </div>
  </div>
</div>

<div class="cm-correspondence" cmAutoScroll>
  <h3>Start new correspondence</h3>
  <div class="cm-correspondence__interaction-wrapper" [formGroup]="form">
    <div class="cm-correspondence__inputs">
      <textarea
        cmFocusTextarea
        class="cm-correspondence__inputs__textarea"
        (focus)="handlerHandler()"
        (input)="onTextInput()"
        rows="1"
        formControlName="comment"
        maxlength="4000"
        placeholder="Add correspondence..."></textarea>
    </div>

    <div class="cm-correspondence__buttons" *ngIf="showButtons$ | async">
      <button mat-flat-button (click)="submitCorrespondence(null)">Submit</button>
      <button mat-button (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
