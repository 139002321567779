<div [class]="['status-label', 'status-label--' + color]">
  <svg
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      [class]="['status-label__icon', 'status-label__icon--' + color]"
      d="M9.78564 4.5C9.78564 6.98528 7.77093 9 5.28564 9C2.80036 9 0.785645 6.98528 0.785645 4.5C0.785645 2.01472 2.80036 0 5.28564 0C7.77093 0 9.78564 2.01472 9.78564 4.5Z" />
  </svg>

  <span class="text-content">{{ text }} </span>
  <mat-icon fontIcon="arrow_drop_down" />
</div>
