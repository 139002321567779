import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { CategoryService } from '@shared/services/category.service';
import { CaseTypeInstance, CategoryInstance, CategoryListRequest } from '../category.model';
import {
  MatCell, MatCellDef,
  MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource, MatTextColumn,
} from '@angular/material/table';
import { VrStorybookLibModule } from '@vr/vr-storybook-lib';
import { NgForOf } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { CategoryDetailsComponent } from '../category-details/category-details.component';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { MatButton } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { GlobalVariablesService } from '@shared/services/global-variables.service';
import { debounceTime, map, merge, startWith, switchMap, of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'cm-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrl: './list-categories.component.scss',
  standalone: true,
  imports: [
    MatLabel,
    MatTable,
    MatTextColumn,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    VrStorybookLibModule,
    MatPaginator,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    MatHeaderRowDef,
    MatCellDef,
    MatHeaderCellDef,
    MatFormField,
    MatSelect,
    MatOption,
    MatButton,
    ReactiveFormsModule,
    NgForOf,
    DateFormatPipe,
    MatInput,
    MatSortModule,
  ],
})
export class ListCategoriesComponent implements AfterViewInit {
  displayedColumns: string[] = ['code', 'name', 'deployed', 'enabled', 'validFrom'];
  dataSource = new MatTableDataSource<CategoryInstance>();

  totalRecords = 0;
  sortColumn = 'name';
  sortDirection: SortDirection = 'asc';

  selectedCaseTypeInstance?: number;
  caseTypeInstances: CaseTypeInstance[] = [];
  caseTypeCategories: CategoryInstance[] = [];

  categoryFilter: FormGroup = new FormGroup({ caseTypeInstanceId: new FormControl(''), name: new FormControl('') });

  categoryListRequest: CategoryListRequest = {
    page: 1,
    pageSize: 10,
  };

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private categoryService: CategoryService,
    public dialog: MatDialog,
    public globals: GlobalVariablesService,
  ) {
    this.getCaseTypeInstances();
  }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.categoryFilter.valueChanges, this.sort.sortChange, this.paginator.page, this.dialog.afterAllClosed) // How to trigger after close only if modal returned smth
      .pipe(
        startWith({}),
        switchMap(() => {
          this.selectedCaseTypeInstance = this.categoryFilter.value.caseTypeInstanceId;

          if (this.categoryFilter.value.caseTypeInstanceId) {
            return this.getTableData().pipe(catchError(() => observableOf(null)));
          }
          return observableOf(null);
        }),
        map((data) => {
          if (data === null) {
            return [];
          }

          this.totalRecords = data.totalRecords;
          return data.categories;
        }),
      )
      .subscribe((response) => {
        this.dataSource.data = response;
      });
  }

  getTableData() {
    this.categoryListRequest = {
      ...this.categoryListRequest,
      ...this.categoryFilter.value,
      page: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      sortColumn: this.sort.active,
      sortDirection: this.sort.direction || null,
    };

    return this.categoryService.getAllCategories(this.categoryListRequest);
  }

  getCaseTypeInstances(): void {
    this.categoryService.getAllCaseTypeInstances().subscribe({
      next: response => {
        this.caseTypeInstances = response;

        this.categoryFilter
          .get('caseTypeInstanceId')
          ?.setValue(this.caseTypeInstances[0]?.id);

        this.selectedCaseTypeInstance = this.caseTypeInstances[0].id;
      },
    });
  }

  onRowClick(category: CategoryInstance): void {
     this.dialog.open(CategoryDetailsComponent, {
      data: {
        category,
        caseTypeInstances: this.caseTypeInstances,
        caseTypeInstanceCategories: this.caseTypeCategories,
      },
    });
  }
}
